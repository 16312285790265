<template>
  <div>
    <div class="d-flex justify-start align-center mb-8">
      <div>
        <v-avatar
          color="primary"
          class="v-avatar-light-bg primary--text mr-2"
          size="35"
        >
          <v-icon
            dense
            color="primary"
          >
            {{ icons.mdiTownHall }}
          </v-icon>
        </v-avatar>
      </div>
      <div>
        <div v-if="isLoadingInstance">
          <v-progress-circular
            color="primary"
            :size="30"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-else>
          <span class="d-block text-caption text-capitalize black--text">{{ instance.company_types_uuid.type }}</span>
          <span
            class="d-block text-subtitle-1 font-weight-bold black--text"
            style="line-height: 1.5rem !important"
          >
            {{ instance.name }}
          </span>
        </div>
      </div>
    </div>

    <v-card>
      <DataTablePagination
        :page="page"
        :total-items="totalItems"
        :headers="headers"
        :items="employees"
        :total-pages.sync="totalPages"
        :is-loading="isLoadingEmployee"
        :no-add-button="noAddButton"
        title="Pegawai"
        subtitle="Halaman untuk menampilkan daftar pegawai"
        @change-page="paginationHandler"
        @live-search="searchHandler"
      >
      </DataTablePagination>
    </v-card>
  </div>
</template>

<script>
import { mdiClose, mdiDotsVertical, mdiTownHall } from '@mdi/js'
import DataTablePagination from '../components/DataTablePagination.vue'

export default {
  name: 'UserInstance',
  components: {
    DataTablePagination,
  },
  data() {
    return {
      dialog: '',
      search: '',
      confirmDialog: false,
      confirmCancels: false,
      validateDelete: null,
      isLoadingEmployee: true,
      isLoadingButton: false,
      addFormDialog: false,
      editFormDialog: false,
      confirmDeleteDialog: false,
      noAddButton: true,
      page: 1,
      totalPages: 1,
      totalItems: 1,
      itemsPerPage: 10,
      filterClassUpper: true,
      icons: {
        mdiDotsVertical,
        mdiTownHall,
        mdiClose,
      },
      headers: [
        { text: 'No.', value: 'index' },
        { text: 'Nama Lengkap', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Jenis Kelamin', value: 'gender' },
        { text: 'Tanggal Lahir', value: 'birthday' },
        { text: 'No. HP', value: 'phone_number' },
      ],
      employees: [],
      serviceEmployee: 'employee',
      instance: {},
      isLoadingInstance: false,
      serviceInstance: 'instance',
      instanceUuid: JSON.parse(localStorage.getItem('user')).user.company_uuid,
      filterQuery: {
        search: '',
      },
    }
  },
  watch: {
    page: {
      handler() {
        this.getEmployee(this.filterQuery)
      },
    },
  },
  created() {
    this.getDetailInstance()
    this.getEmployee()
  },
  methods: {
    async getDetailInstance() {
      this.isLoadingInstance = true

      await this.$services.ApiServices.get(this.serviceInstance, this.instanceUuid).then(
        ({ data }) => {
          this.instance = data.data
          this.isLoadingInstance = false
        },
        err => {
          console.error(err)
          this.isLoadingInstance = false
        },
      )

      this.isLoadingInstance = false
    },

    async getEmployee(params = {}) {
      this.isLoadingEmployee = true

      await this.$services.ApiServices.get(this.serviceEmployee, this.instanceUuid, {
        ...params,
        page: this.page,
      }).then(
        ({ data }) => {
          this.employees = data.data.map((type, index) => ({
            ...type,
            index: index + 1,
          }))
          this.totalPages = data.meta.last_page
          this.totalItems = data.meta.total
          this.isLoadingEmployee = false
        },
        err => {
          console.error(err)
          this.isLoadingEmployee = false
        },
      )

      this.isLoadingEmployee = false
    },

    searchHandler(search) {
      this.filterQuery.search = search
      this.page = 1
      this.getEmployee(this.filterQuery)
    },

    paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style scoped>
.book-packet-header ul li {
  list-style: none;
  margin-bottom: 1rem;
}

.search-book > .v-input__control > .v-text-field__details {
  display: none;
}

.search-book > .v-input__control > .v-input__slot {
  margin: 0 !important;
}
</style>
